<template>
  <div>
    <BaseHeaderB></BaseHeaderB>
    <h3>Your payment has been successful</h3>
    <p>Access all the latest features with a click of a button.</p>
  </div>
</template>

<script>
export default {
  async created() {
    console.log(this.$route.query.session_id);
    const stripe = require("stripe")("sk_test_4eC39HqLyjWDarjtT1zdp7dc");

    await stripe.checkout.sessions
      .retrieve(
        "cs_test_a1krawmcP9YmMOhqsd9CpT7NeeoP9yersYQRc45Sh5eoppGkFUgXwFYIcl"
      )
      .then((session) => {
        console.log("Running ", session);
      });
  },
};
</script>

<style></style>
